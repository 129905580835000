import api from '@/utils/request'

// 获取餐厅信息
export function getRestaurant(){
    return api().get('/web/bookSetting/getRestaurant')
}
/**
 * @description: 全国行政区划
 */
export const GetRegions = (data) =>
	api().get('/web/region/info',{params:data});
    
// 获取用户信息
export function getUserinfo(){
    return api().get('web/user/getInfo')
}
// 根据电话号码获取用户信息
export function getUserinfoByPhone(data){
    return api().get('/pad/user/getUserByPhone',{params:data})
}
// 获取订单统计
export function getOrderCount(data){
    return api.post('/pad/order/getOrderCount',data);
}
// 获取所有区域
export function getAllArea(){
    return api().get('/pad/desk/getAllArea')
}
// 获取桌位订单信息
export function getDeskOrder(data){
    return api.post('/pad/desk/getDeskOrder',data);
}
// 占桌发送消息
export function sendOccupyTable(data){
    return api.post('/pad/order/occupyTable',data);
}
// 退出前清空所有占桌
export function clearALlSelectedTables(data){
    return api.post('/pad/order/clearALlSelectedTables',data);
}
// 获取就餐时间
export function findRestaurantMealTime(data){
    return api().get('/pad/restaurant/findRestaurantMealTime',{params:data})
}
// 获取订单列表
export function getOrderList(data){
    return api.post('/app/order/searchOrderVoByEatDate',data)
}
// 获取来电记录
export function getCallRecord(data){
    return api().get('/pad/call/getCallRecord',{params:data})
}
// 处理来电
export function setCallRecord(data,orderInfo){
    return api().get('/pad/call/setCallRecord/'+data+'/'+orderInfo)
}
// 获取预约记录
export function getAppointList(data){
    return api.post('/app/order/searchOrderVoByEatDate',data)
}
// 安排就餐
// export function dining(data){
//     return api().get('/web/book/dining',{params:data})
// }
export function resetDeskAndDate(data){
    return api.post('/pad/order/resetDeskAndDate',data)
}
// 创建订单
export function saveOrder(data){
    return api.post('/pad/order/saveOrder',data)
}
// 创建订单发送短信
export function sendMessage(data){
    return api().get('/web/restaurantCustomer/notify/send'+data)
}
// 创建散客单
export function saveFit(data){
    return api.post('/pad/order/saveFit',data)
}
// 创建预留单
export function saveRevered(data){
    return api.post('/pad/order/saveRevered',data)
}
// 获取收款人
export function getPayee(){
    return api().get('/web/book/getPayee')
}
// 获取付款方式
export function getRestaurantSettleAccounts(){
    return api().get('/web/businessSetting/getRestaurantSettleAccounts')
}
// 保存定金
export function saveDeposit(data){
    return api.post('/pad/order/saveDeposit',data)
}
// 获取宴会类型
export function getBanquetTypeRealtion(){
    return api().get('/pad/banquet/getBanquetTypeRealtion')
}
// 获取服务等级
export function getServiceLevel(){
    return api().get('/pad/restaurant/getServiceLevel')
}
// 获取所有标签
export function getAllLabel(data){
    return api().get('/pad/banquet/getAllLabel',{params:data})
}
// 获取打印信息
export function getPrintInfo(data){
    return api().post('/web/book/getPrintInfo',data)
}
// logout
export function logout(){
    return api().delete('/auth/logout')
}
// 删除来电记录
export function deleteCallById(data){
    return api().get('/pad/call/removeCallRecord/'+data)
}
// 获取消息通知状态
export function getCloseNotice(){
    return api().get('/web/user/getCloseNotice')
}
// 切换通知开关
export function switchCloseNotice(){
    return api().get('/web/user/switchCloseNotice')
}
// 更新所有通知为已读
export function toNoticeHas(){
    return api().get('/pad/order/toNoticeHas')
}
// 获取订单通知
export function getNoticeList(){
    return api().get('/pad/order/getNoticeList')
}
// 更新订单通知阅读状态
export function editNoticeStatus(data){
    return api().get('/pad/order/editNoticeStatus',{params:data})
}